export class UserModel {
    accessLevelID = "1";
    adLocalAccountGuid = ""
    firstName = ""
    jobTitle = ""
    lastName = ""
    loginEmail = ""
    title = ""
    titleCompany = ""
    userGUID = ""
    userID = "205"
    userTypeID = "1"
    webSite = ""
}